<template>
  <q-page class="q-pl-xs q-pr-xs q-pl-md q-pr-md">
    <div class="row items-center q-gutter-md justify-center" style="margin-bottom:10px;">
      <q-btn @click="sendSar" class="bg-white" rounded v-if="loans && loans.length">Email Me a Statement of Accounts</q-btn>
    </div>
    <div class="row items-center q-gutter-md" v-if="!loading">
      <Loan :key="i" v-for="(loan, i) of currentItems" :loan="loan"/>

      <pagination :per-page="itemsInPage" :records="loans.length" v-model="currentPage"/>

    </div>
  </q-page>
</template>

<script>
import failedRequest from '../mixins/failed-request'
import Loan from '../components/Loan'
import Pagination from '../components/Pagination'

export default {
  name: 'PageLoans',
  mixins: [failedRequest],
  props: {
    itemsInPage: {
      type: Number,
      default: 5
    }
  },
  components: {
    Loan,
    Pagination
  },
  meta: {
    title: 'Loans'
  },
  async mounted () {
    await this.$dispatch([
      {
        action: 'loans/getLoans',
        message: 'Loading Loans Data...'
      },
      {
        action: 'applications/getApplications',
        message: 'Loading Applications Data...'
      }
    ])
  },
  methods: {
    async sendSar () {
      await this.$dispatch({
        action: 'customer/sendSar',
        message: 'We are processing your request...',
        successMessage: 'Accounts statement will be sent to your email shortly.'
      })
    }
  },
  data () {
    return {
      loading: true,
      currentPage: 0
    }
  },
  computed: {
    loans () {
      return this.$store.getters['loans/sortLoans']
    },
    pages () {
      return parseInt(this.loans.length / this.itemsInPage) + 1
    },
    currentItems () {
      let currentItem = this.currentPage * this.itemsInPage
      return this.loans.slice(currentItem, currentItem + this.itemsInPage)
    }
  }
}
</script>

<style>
</style>
